import React from "react";
import { Link, graphql } from "gatsby";
import dayjs from "dayjs";
import BackIcon from "@material-ui/icons/ArrowBackIos";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default function Template({ data }) {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <SEO title="About" keywords={[`orsio`, `biotronik`, `drug eluting`]} />
      <div className="container mx-auto px-4 text-left">
        <Link to="/news" className="flex items-center	">
          <BackIcon fontSize="small" style={{ fill: "#587992" }}>
            backicon
          </BackIcon>
          <span className="uppercase">In the news</span>
        </Link>
        <div className="heading text-bioGrayBlue text-2xl md-heading font-bold mb-0">
          {post.frontmatter.title}
        </div>
        <div className="mb-0">
          {dayjs(post.frontmatter.date).format("dddd, MMMM DD, YYYY")}
        </div>
        <div className="uppercase mb-6">{post.frontmatter.location}</div>
        <div
          className=""
          style={{}}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </Layout>
  );
}

export const postQuery = graphql`
  query BlogByPostPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        date
        location
        author
      }
    }
  }
`;
